import { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse' />
    Loading...
  </div>
)

let isAuthenticated = false

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      !isAuthenticated
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isAuthenticated
        ? <Component {...props} />
        : <Redirect to='/login' />
    )}
  />
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isAuthenticated: false
    }
  }

  async isAuthenticated() {
    try {
      const session = await Auth.currentSession()
      if (session.isValid()) {
        console.log('Found valid session')
        const cognitogroups = session.accessToken.payload['cognito:groups'];
        console.log(`Groups: ${cognitogroups.join(', ')}`)
        if (cognitogroups.includes('administrators')) {
          return true
        }
      } 
      return false
    } catch (e) {
      if (e !== 'No current user') {
        console.error(e)
      }
    }
    // write your condition here
    return false
  }

  componentDidMount() {
    this.isAuthenticated().then(isAuth => {
      this.setState({
        loading: false,
        isAuthenticated: isAuth
      })
      isAuthenticated = isAuth
      console.log(`isAuthenticated: ${this.state.isAuthenticated}`)
      history.push('/dashboard')
    })
  }

  render() {
    if (this.state.loading) {
      return loading
    } else {
      return (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <UnauthenticatedRoute exact path='/login' name='Login Page' component={Login} />} />
              {/* <Route exact path='/register' name='Register Page' render={props => <Register {...props} />} /> */}
              <UnauthenticatedRoute exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
              <UnauthenticatedRoute exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
              <AuthenticatedRoute path='/' name='Home' component={TheLayout} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      )
    }
  }
}

export default App
