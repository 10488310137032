export const logo = ['539 100', `
  <title>peasy</title>
  <svg width="539" height="100" viewBox="0 0 539 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M42.75 32.86C46.61 35.02 49.63 38.06 51.83 41.97C54.02 45.88 55.12 50.44 55.12 55.63C55.12 60.83 54.02 65.38 51.83 69.29C49.63 73.2 46.61 76.24 42.75 78.4C38.89 80.56 34.61 81.64 29.91 81.64C23.45 81.64 18.37 79.61 14.67 75.54V99.1H0V30.38H14.01V36.2C17.65 31.82 22.95 29.63 29.9 29.63C34.61 29.62 38.89 30.7 42.75 32.86ZM36.64 65.81C39.05 63.28 40.26 59.88 40.26 55.62C40.26 51.36 39.05 47.97 36.64 45.43C34.22 42.9 31.14 41.63 27.38 41.63C23.62 41.63 20.53 42.9 18.12 45.43C15.71 47.96 14.5 51.36 14.5 55.62C14.5 59.88 15.71 63.27 18.12 65.81C20.53 68.34 23.62 69.61 27.38 69.61C31.14 69.62 34.22 68.35 36.64 65.81Z" fill="white"/>
  <path d="M97.39 68.72C99.43 67.94 101.32 66.7 103.08 65.01L110.89 73.46C106.12 78.91 99.17 81.63 90.01 81.63C84.3 81.63 79.26 80.52 74.87 78.3C70.48 76.08 67.09 73 64.71 69.05C62.33 65.11 61.14 60.63 61.14 55.62C61.14 50.68 62.32 46.22 64.67 42.24C67.02 38.26 70.25 35.17 74.36 32.95C78.47 30.73 83.09 29.62 88.23 29.62C93.06 29.62 97.45 30.64 101.4 32.67C105.35 34.71 108.5 37.65 110.85 41.49C113.2 45.34 114.38 49.89 114.38 55.15L76.95 62.38C78.02 64.88 79.69 66.76 81.98 68.01C84.27 69.26 87.07 69.89 90.4 69.89C93.02 69.9 95.35 69.51 97.39 68.72ZM79.19 44.27C76.87 46.65 75.65 49.93 75.52 54.13L100.16 49.34C99.47 46.71 98.06 44.61 95.93 43.05C93.8 41.49 91.23 40.7 88.22 40.7C84.52 40.7 81.51 41.89 79.19 44.27Z" fill="white"/>
  <path d="M175.6 30.37V80.88H161.59V75.06C157.95 79.44 152.69 81.63 145.79 81.63C141.02 81.63 136.71 80.57 132.86 78.44C129 76.31 125.98 73.28 123.78 69.33C121.58 65.39 120.49 60.82 120.49 55.62C120.49 50.42 121.59 45.85 123.78 41.91C125.97 37.97 129 34.93 132.86 32.8C136.72 30.67 141.03 29.61 145.79 29.61C152.25 29.61 157.3 31.64 160.93 35.71V30.36H175.6V30.37ZM157.54 65.81C159.98 63.28 161.21 59.88 161.21 55.62C161.21 51.36 159.99 47.97 157.54 45.43C155.09 42.9 152.02 41.63 148.32 41.63C144.56 41.63 141.45 42.9 139.01 45.43C136.56 47.96 135.34 51.36 135.34 55.62C135.34 59.88 136.56 63.27 139.01 65.81C141.45 68.34 144.56 69.61 148.32 69.61C152.02 69.62 155.09 68.35 157.54 65.81Z" fill="white"/>
  <path d="M193.84 80.08C189.83 79.05 186.63 77.75 184.25 76.18L189.14 65.67C191.4 67.11 194.12 68.28 197.32 69.19C200.52 70.1 203.65 70.55 206.73 70.55C212.94 70.55 216.04 69.02 216.04 65.95C216.04 64.51 215.19 63.48 213.5 62.85C211.81 62.22 209.2 61.69 205.69 61.25C201.55 60.63 198.13 59.91 195.44 59.09C192.74 58.28 190.41 56.84 188.43 54.77C186.45 52.7 185.47 49.76 185.47 45.95C185.47 42.76 186.39 39.93 188.24 37.45C190.09 34.98 192.79 33.05 196.33 31.68C199.87 30.3 204.06 29.61 208.89 29.61C212.46 29.61 216.02 30 219.56 30.78C223.1 31.56 226.03 32.64 228.35 34.02L223.46 44.44C219.01 41.94 214.15 40.68 208.88 40.68C205.74 40.68 203.39 41.12 201.83 41.99C200.26 42.87 199.48 43.99 199.48 45.37C199.48 46.94 200.33 48.03 202.02 48.66C203.71 49.29 206.41 49.88 210.11 50.44C214.25 51.13 217.63 51.87 220.27 52.65C222.9 53.43 225.19 54.86 227.14 56.92C229.08 58.99 230.06 61.87 230.06 65.56C230.06 68.69 229.12 71.48 227.24 73.92C225.36 76.36 222.62 78.26 219.01 79.6C215.4 80.94 211.12 81.62 206.17 81.62C201.96 81.63 197.85 81.12 193.84 80.08Z" fill="white"/>
  <path d="M290.06 30.37V72.43C290.06 81.69 287.71 88.58 283.01 93.08C278.31 97.59 271.47 99.84 262.51 99.84C257.74 99.84 253.25 99.24 249.01 98.06C244.78 96.87 241.22 95.15 238.34 92.9L244.17 82.39C246.3 84.14 248.9 85.52 251.98 86.52C255.05 87.52 258.15 88.02 261.29 88.02C266.18 88.02 269.75 86.89 272.01 84.64C274.27 82.39 275.4 78.94 275.4 74.31V72.62C273.52 74.5 271.29 75.94 268.72 76.94C266.15 77.94 263.39 78.44 260.44 78.44C253.79 78.44 248.53 76.55 244.64 72.76C240.75 68.97 238.81 63.29 238.81 55.72V30.37H253.48V53.56C253.48 61.76 256.9 65.86 263.73 65.86C267.24 65.86 270.06 64.72 272.19 62.43C274.32 60.15 275.39 56.75 275.39 52.24V30.36H290.06V30.37Z" fill="white"/>
  <path d="M302.75 79.01C300.99 77.26 300.12 75.07 300.12 72.44C300.12 69.75 301 67.57 302.75 65.91C304.5 64.25 306.67 63.42 309.24 63.42C311.81 63.42 313.97 64.25 315.73 65.91C317.48 67.57 318.36 69.74 318.36 72.44C318.36 75.07 317.48 77.26 315.73 79.01C313.97 80.76 311.81 81.64 309.24 81.64C306.67 81.63 304.51 80.76 302.75 79.01Z" fill="white"/>
  <path d="M374.28 35.26C378.14 39.02 380.06 44.59 380.06 51.97V80.89H365.39V54.23C365.39 50.23 364.51 47.24 362.76 45.26C361 43.29 358.46 42.3 355.14 42.3C351.44 42.3 348.49 43.44 346.3 45.73C344.1 48.01 343.01 51.41 343.01 55.92V80.89H328.34V30.38H342.35V36.3C344.29 34.17 346.71 32.53 349.59 31.37C352.47 30.21 355.64 29.63 359.09 29.63C365.36 29.62 370.42 31.5 374.28 35.26Z" fill="white"/>
  <path d="M442.45 30.37V80.88H428.53V74.87C426.59 77.06 424.27 78.74 421.57 79.89C418.87 81.05 415.96 81.63 412.82 81.63C406.17 81.63 400.91 79.72 397.02 75.9C393.13 72.08 391.19 66.42 391.19 58.91V30.37H405.86V56.75C405.86 64.89 409.28 68.96 416.11 68.96C419.62 68.96 422.44 67.82 424.57 65.53C426.7 63.25 427.77 59.85 427.77 55.34V30.37H442.45Z" fill="white"/>
  <path d="M515.24 0.630005L469.89 26.49L476.6 38.98L499.51 25.59L497.56 31.94C497.08 33.42 496.57 34.88 496.01 36.31C493.78 42.05 490.86 47.39 487.18 51.91C485.33 54.16 483.3 56.2 481.09 57.99C478.87 59.77 476.47 61.28 473.93 62.51C468.84 65 462.53 66.53 457.1 66.53H454.37V81.27H457.64C461.46 81.27 465.32 80.71 469.15 79.87C472.98 79.04 476.76 77.77 480.37 76.09C483.98 74.41 487.41 72.31 490.54 69.87C493.66 67.42 496.49 64.64 499 61.66C504 55.68 507.74 48.95 510.53 42.05C511.24 40.32 511.87 38.58 512.47 36.83L514.55 30.4L526.04 53.84L538.59 47.12L515.24 0.630005Z" fill="white"/>
  </svg>  
`]
