const isProduction = process.env.REACT_APP_STAGE === 'production'
const stage = isProduction ? 'production' : 'test'
const _url = isProduction ? 'https://v3.api.peasy.nu' : 'https://v3test.api.peasy.nu'

const config = {
  stage: stage,
  baseURL: `${_url}/mobile-api`,
  baseURLBackoffice: `${_url}/backoffice-api`,
  apiGateway: {
    REGION: 'eu-west-1',
    URL: _url
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_1ofNu0gqN',
    APP_CLIENT_ID: '4ith9cnmmgacea2hb97jia9jtk',
    IDENTITY_POOL_ID: 'eu-west-1:62a16e3c-d597-4651-bf69-6a8cf47591be'
  }
}

if (isProduction) {
  config.cognito.USER_POOL_ID = 'eu-west-1_GOmTCkqrD'
  config.cognito.APP_CLIENT_ID = '7btgn174ebn1jamb9oqh4um6nc'
  config.cognito.IDENTITY_POOL_ID = 'eu-west-1:9265b32c-88a4-4e28-b326-8237b6c58061'
}

export default config
